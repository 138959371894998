import moment from "moment";
import React, {
  createRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Badge,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  IoCartOutline,
  IoCloseOutline,
  IoCloudOutline,
  IoCreateOutline,
  IoGlobeOutline,
  IoPrintOutline,
  IoSearchOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { Swiper, SwiperSlide } from "swiper/react";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Print from "../../components/Print";
import { isStatus, Status } from "../../components/StatusOrder";
import Button from "../../components/UI/Button";
import DatePeriod from "../../components/UI/DatePeriod";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import CustomModal from "../../components/utils/CustomModal";
import socket from "../../config/socket";
import { convertColor } from "../../helpers/convertColor";
import { deliveryData, paymentData, typeData } from "../../helpers/order";
import { customPrice } from "../../helpers/product";
import { deleteOrder, getOrders } from "../../services/order";
import { updateNotification } from "../../store/reducers/notificationSlice";
import { Pagination } from "swiper/modules";

const OrderComponent = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const inputRef = createRef();
  const brand = useSelector((state) => state.brand.active);
  const user = useSelector((state) => state.auth.user);
  const affiliate = useSelector((state) => state.affiliate.active);
  const status = useSelector((state) => state.status.data);
  const orderNotification = useSelector((state) => state.notification.order);

  const statuses =
    status?.length > 0
      ? status.map((e) => ({
          title: e.name,
          value: e.value,
        }))
      : [];

  statuses.unshift({
    title: "Все",
    value: "",
  });

  const [orders, setOrders] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    isShow: false,
    id: false,
  });

  const [statistic, setStatistic] = useState({
    order: 0,
    total: 0,
    average: 0,
    delivery: {
      count: 0,
      total: 0,
    },
    pickup: {
      count: 0,
      total: 0,
    },
    hall: {
      count: 0,
      total: 0,
    },
    online: {
      count: 0,
      total: 0,
    },
    card: {
      count: 0,
      total: 0,
    },
    cash: {
      count: 0,
      total: 0,
    },
    app: {
      count: 0,
      total: 0,
    },
    site: {
      count: 0,
      total: 0,
    },
  });

  const orderColumns = [
    {
      name: "Товары",
      selector: "products",
      width: "90px",
      showDescIcon: false,
      showDesc: true,
      cell: (row) => `${row?.products?.length ?? 0} шт`,
    },
    {
      name: "Дата",
      selector: "createdAt",
      showDesc: true,
      width: "70px",
      showDescIcon: false,
      cell: (row) => (
        <>
          <div>{moment(row.createdAt).format("DD.MM")}</div>
          <div className="fw-7">{moment(row.createdAt).format("kk:mm")}</div>
        </>
      ),
    },
    {
      name: "Телефон",
      selector: "phone",
      showDescIcon: false,
      showDesc: true,
      cell: (row) => <div className="d-none d-md-inline">{row.phone}</div>,
    },
    {
      name: <IoGlobeOutline size={18} />,
      selector: "type",
      width: "60px",
      align: "center",
      showDesc: true,
      showDescIcon: false,
      cell: (row) => (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={"type_" + row.id}>{typeData(row.type).text}</Tooltip>
          }
        >
          <img src={typeData(row.type).icon} />
        </OverlayTrigger>
      ),
    },
    {
      name: <IoCartOutline size={18} />,
      selector: "delivery",
      showDesc: true,
      width: "60px",
      align: "center",
      showDescIcon: false,
      cell: (row) => (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={"delivery_" + row.id}>
              {t(deliveryData(row.delivery).text)}
            </Tooltip>
          }
        >
          <img src={deliveryData(row.delivery).icon} />
        </OverlayTrigger>
      ),
    },
    {
      width: "80px",
      align: "center",
      showDescIcon: false,
      showDesc: true,
      name: <IoCloudOutline size={18} />,
      selector: "apiId",
      cell: (row) => {
        var log = "";

        try {
          log = JSON.parse(row.log);
        } catch (error) {
          log = row.log;
        }

        return log && log?.status === 200 ? (
          <Badge className="badge-sm" bg="success">
            OK
          </Badge>
        ) : log &&
          (log?.status === 500 ||
            log?.status === 502 ||
            log?.status === 504) ? (
          <Badge className="badge-sm" bg="danger">
            ERROR
          </Badge>
        ) : (
          <Badge className="badge-sm" bg="secondary">
            {t("НЕТ")}
          </Badge>
        );
      },
    },
    {
      name: "Итого",
      width: "170px",
      align: "right",
      selector: "total",
      showDesc: true,
      showDescIcon: false,
      cell: (row) => {
        return (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={"payment_" + row.id}>
                {t(paymentData(row.payment).text)}
              </Tooltip>
            }
          >
            <div className="d-flex align-items-center">
              <span>
                {row.discount > 0 &&
                  customPrice(
                    row.total +
                      (row.deliveryPrice ?? 0) -
                      (row.pointWriting ?? 0) -
                      (row.discount ?? 0),
                    false,
                    user?.lang
                  )}
                <div className={row.discount > 0 ? "discount" : ""}>
                  {customPrice(
                    row.total +
                      (row.deliveryPrice ?? 0) -
                      (row.pointWriting ?? 0),
                    false,
                    user?.lang
                  )}
                </div>
              </span>
              <span
                className={
                  "ms-1" +
                  (row?.transactions[0]?.status?.toLowerCase() === "ok"
                    ? " payment-success"
                    : "")
                }
              >
                {paymentData(row.payment).icon}
              </span>
            </div>
          </OverlayTrigger>
        );
      },
    },
    // {
    //   name: "Статус",
    //   width: "160px",
    //   align: "center",
    //   selector: "status",
    //   cell: (row) => <Status data={row} />,
    // },
    {
      width: "100px",
      selector: "action",
      align: "right",
      cell: (row) => {
        const printRef = useRef();
        const handlePrint = useReactToPrint({
          content: () => printRef.current,
        });
        return (
          <>
            <div className="d-flex align-items-center">
              <Link onClick={handlePrint} className="me-3">
                <IoPrintOutline size={22} />
              </Link>
              <Link to={"/order/" + row.id} className="me-2">
                <IoCreateOutline size={22} />
              </Link>
            </div>
            {row?.products?.length > 0 && <Print ref={printRef} data={row} />}
          </>
        );
      },
    },
  ];

  const header = useMemo(() => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h5 className="fw-7">
              {selected.length > 0
                ? `${t("Выбрано")} ${selected.length}`
                : t("Заказы")}
            </h5>
          </div>
          <div className="d-flex align-items-center">
            {/* <Link to="create" className="btn-primary-outline me-3">
              <IoAdd size={18} />
            </Link> */}
            <Button
              disabled={selected.length === 0}
              className="btn-light"
              onClick={() => setModalDelete({ show: true, id: false })}
            >
              <IoTrashOutline size={18} />
            </Button>
          </div>
        </div>
        <div>
          <Row>
            {status?.data?.length > 0 && (
              <Col md={2}>
                <Select
                  classNameContainer="w-100"
                  label="Статус"
                  data={statuses}
                  value={searchParams.get("status") ?? ""}
                  onClick={(e) => {
                    searchParams.set("status", e.value);
                    setSearchParams(searchParams);
                    onSearch();
                  }}
                />
              </Col>
            )}
            <Col md={2}>
              <Select
                classNameContainer="w-100"
                label="Способ доставки"
                data={[
                  { title: "Все", value: "" },
                  { title: "Доставка", value: "delivery" },
                  { title: "Самовывоз", value: "pickup" },
                  { title: "В зале", value: "hall" },
                ]}
                value={searchParams.get("delivery") ?? ""}
                onClick={(e) => {
                  searchParams.set("delivery", e.value);
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col>
            <Col md={2}>
              <Select
                classNameContainer="w-100"
                label="Тип оплаты"
                data={[
                  { title: "Все", value: "" },
                  { title: "Онлайн", value: "online" },
                  { title: "Картой", value: "card" },
                  { title: "Наличными", value: "cash" },
                ]}
                value={searchParams.get("payment") ?? ""}
                onClick={(e) => {
                  searchParams.set("payment", e.value);
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col>
            <Col md={2}>
              <Select
                classNameContainer="w-100"
                label="Статус"
                data={[
                  { title: "Все", value: "" },
                  { title: "Новые", value: "new" },
                  { title: "Предзаказ", value: "reservation" },
                  { title: "Готовится", value: "preparing" },
                  { title: "На выдаче", value: "prepared" },
                  { title: "Доставка", value: "delivery" },
                  { title: "Завершен", value: "done" },
                  { title: "Отменен", value: "canceled" },
                ]}
                value={searchParams.get("status") ?? ""}
                onClick={(e) => {
                  searchParams.set("status", e.value);
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col>
            {/* <Col md={3}>
              <Select
                classNameContainer="w-100"
                label="Сортировка"
                data={[
                  { title: "По дате добавления: новые", value: "" },
                  { title: "По дате добавления: старые", value: "createold" },
                  { title: "Стоимость: больше", value: "pricemore" },
                  { title: "Стоимость: меньше", value: "priceless" },
                ]}
                value={searchParams.get("sort") ?? ""}
                onClick={(e) => {
                  searchParams.set("sort", e.value);
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col> */}
            <Col md={6}>
              <Input
                ref={inputRef}
                placeholder={t("Найти")}
                className="w-100"
                onChange={(e) => {
                  searchParams.set("text", e);
                  setSearchParams(searchParams);
                }}
                rightIcon={() => <IoSearchOutline size={22} />}
                value={searchParams.get("text") ?? ""}
                rightIconClick={() => onSearch()}
                onKeyDown={(e) => e === "Enter" && onSearch()}
              />
              {searchParams.get("text")?.length > 0 && (
                <Button
                  className="btn-light ms-3"
                  onClick={() => {
                    searchParams.delete("text");
                    setSearchParams(searchParams);
                    onSearch();
                    if (inputRef.current) {
                      inputRef.current.value = "";
                    }
                  }}
                >
                  <IoCloseOutline size={22} />
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }, [selected, searchParams, modalDelete, t, user]);

  const handleDateRangeSelected = (startDate, endDate) => {
    if (startDate || endDate) {
      searchParams.delete("stat");
      startDate &&
        searchParams.set("start", moment(startDate).format("YYYY-MM-DD"));
      endDate && searchParams.set("end", moment(endDate).format("YYYY-MM-DD"));
      setSearchParams(searchParams);
      onSearch();
    }
  };

  const getData = useCallback(async () => {
    dispatch(updateNotification({ order: -1 }));
    const paramsObject = {};
    searchParams.forEach((value, key) => {
      paramsObject[key] = value;
    });

    if (affiliate && affiliate.id) {
      paramsObject.affiliateId = affiliate.id;
    }

    getOrders(paramsObject)
      .then((res) => {
        setOrders((prev) => ({
          ...prev,
          loading: false,
          ...res.orders,
        }));
        setStatistic(res.statistic);
      })
      .finally(() => setOrders((prev) => ({ ...prev, loading: false })));
  }, [searchParams, brand, affiliate]);

  const onSearch = useCallback(() => {
    getData();
  }, [searchParams]);

  useLayoutEffect(() => {
    socket.on("orders-panel/" + brand.id, (data) => {
      if (data?.statuses?.length > 0) {
        let isOrder = orders.items.find((e) => e.id === data.id);
        if (isOrder) {
          orders.items = orders.items.map((e) => {
            if (e.id === data.id) {
              return data;
            }
            return e;
          });
          if (orders.items) {
            setOrders((e) => ({ ...e, items: orders.items }));
          }
        } else {
          getData();
        }
      }
    });
    socket.on("notes/" + brand.id, (data) => {
      if (data?.orderId) {
        let newOrders = orders.items.map((e) => {
          if (e.id === data.orderId) {
            e.noteCount = e?.noteCount ? e.noteCount + 1 : 1;
          }
          return e;
        });
        if (newOrders) {
          setOrders((e) => ({ ...e, items: newOrders }));
        }
      }
    });
    return () => {
      socket.off("orders-panel/" + brand.id);
      socket.off("notes/" + brand.id);
    };
  }, [orders.items, brand]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page"), orderNotification, brand, affiliate]);

  const clickDelete = (id) => {
    deleteOrder(id).then(() => getData());
    setModalDelete({ isShow: false, id: false });
  };

  if (orders.loading) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title={t("Заказы")} />
      <div className="p-2 d-flex justify-content-end flex-row">
        <Select
          title="Выберите период"
          className="fs-09 date-period select-sm"
          data={[
            {
              title: "Сегодня",
              value: "",
            },
            {
              title: "За эту неделю",
              value: "week",
            },
            {
              title: "За " + moment().format("MMMM"),
              value: "month",
            },
            {
              title: "За " + moment().format("YYYY") + " г",
              value: "year",
            },
          ]}
          value={searchParams.get("stat") ?? ""}
          onClick={(e) => {
            if (e.value?.length > 0) {
              searchParams.set("stat", e.value);
              searchParams.delete("start");
              searchParams.delete("end");
            } else {
              searchParams.delete("stat");
            }
            setSearchParams(searchParams);
            onSearch();
          }}
        />
        <DatePeriod
          onDateRangeSelected={handleDateRangeSelected}
          start={searchParams.get("start")}
          end={searchParams.get("end")}
          onDeleteStart={() => {
            searchParams.delete("start");
            searchParams.delete("end");
            setSearchParams(searchParams);
            onSearch();
          }}
          onDeleteEnd={() => {
            searchParams.delete("end");
            setSearchParams(searchParams);
            onSearch();
          }}
        />
      </div>
      <Swiper
        grabCursor={true}
        speed={750}
        spaceBetween={10}
        className="pb-4 pt-2 px-2"
        slidesPerView={"auto"}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        breakpoints={{
          576: {
            slidesPerView: "auto",
          },
          768: {
            slidesPerView: "auto",
          },
          992: {
            slidesPerView: 3,
          },
        }}
      >
        <SwiperSlide>
          <Card className="h-100 shadow-none box-bg-green" body>
            <div className="mask"></div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="pe-3 ">
                <h5 className="mb-0 fw-6 h6">{t("Заказы")}</h5>
                <p className="fs-07 mb-0">Завершенные</p>
                <div>
                  <h2 className="m-0 fs-15">{statistic.order}</h2>
                </div>
              </div>
              <div className="border-left"></div>
              <div className="d-flex flex-column justify-content-center align-items-end">
                <div>
                  <Badge bg="success" className=" mb-1 ms-1">
                    {statistic.orderProcess} в процессе
                  </Badge>
                </div>
                <div>
                  <Badge bg="danger" className=" ms-2">
                    {statistic.orderCanceled} отменено
                  </Badge>
                </div>
              </div>
            </div>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card className="h-100 shadow-none box-bg-yellow" body>
            <div className="mask"></div>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h5 className="mb-2 fw-6 h6">{t("Выручка")}</h5>
                <h2 className="mb-0 fs-15">
                  {customPrice(statistic.total, false, user?.lang)}
                </h2>
              </div>
              <div className="border-left"></div>
              <div>
                <h5 className="mb-2 fw-6 h6">{t("Средний чек")}</h5>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <h2 className="mb-0 fs-15">
                      {customPrice(statistic.average, false, user?.lang)}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </SwiperSlide>
        {statistic.delivery.count > 0 ||
        statistic.pickup.count > 0 ||
        statistic.hall.count > 0 ? (
          <SwiperSlide>
            <Card className="h-100 shadow-none box-bg-violet" body>
              <div className="mask"></div>
              <h5 className="mb-2 fw-6 h6">{t("Тип доставки")}</h5>
              <Row className="gx-1">
                {(statistic.delivery.total > 0 ||
                  statistic.delivery.count > 0) && (
                  <Col xl={6} xxl={4}>
                    <p className="fs-08">{t("Доставка")}</p>
                    <p className="fw-7 fs-09 mb-1">
                      {customPrice(statistic.delivery.total)} (
                      {statistic.delivery.count})
                    </p>
                  </Col>
                )}
                {(statistic.pickup.total > 0 || statistic.pickup.count > 0) && (
                  <Col xl={6} xxl={4}>
                    <p className="fs-08">{t("Самовывоз")}</p>
                    <p className="fw-7 fs-09 mb-1">
                      {customPrice(statistic.pickup.total)} (
                      {statistic.pickup.count})
                    </p>
                  </Col>
                )}
                {(statistic.hall.total > 0 || statistic.hall.count > 0) && (
                  <Col xl={6} xxl={4}>
                    <p className="fs-08">{t("В зале")}</p>
                    <p className="fw-7 fs-09 mb-1">
                      {customPrice(statistic.hall.total)} (
                      {statistic.hall.count})
                    </p>
                  </Col>
                )}
              </Row>
            </Card>
          </SwiperSlide>
        ) : (
          ""
        )}
        {statistic.online.count > 0 ||
        statistic.card.count > 0 ||
        statistic.cash.count > 0 ? (
          <SwiperSlide>
            <Card className="h-100 shadow-none box-bg-violet" body>
              <div className="mask"></div>
              <h5 className="mb-2 fw-6 h6">{t("Тип оплаты")}</h5>
              <Row className="gx-1">
                {(statistic.online.total > 0 || statistic.online.count > 0) && (
                  <Col xl={6} xxl={4}>
                    <p className="fs-08">{t("Онлайн")}</p>
                    <p className="fw-7 fs-09 mb-1">
                      {customPrice(statistic.online.total)} (
                      {statistic.online.count})
                    </p>
                  </Col>
                )}
                {(statistic.card.total > 0 || statistic.card.count > 0) && (
                  <Col xl={6} xxl={4}>
                    <p className="fs-08">{t("Банковская карта")}</p>
                    <p className="fw-7 fs-09 mb-1">
                      {customPrice(statistic.card.total)} (
                      {statistic.card.count})
                    </p>
                  </Col>
                )}
                {(statistic.cash.total > 0 || statistic.cash.count > 0) && (
                  <Col xl={6} xxl={4}>
                    <p className="fs-08">{t("Наличные")}</p>
                    <p className="fw-7 fs-09">
                      {customPrice(statistic.cash.total)} (
                      {statistic.cash.count})
                    </p>
                  </Col>
                )}
              </Row>
            </Card>
          </SwiperSlide>
        ) : (
          ""
        )}
        {statistic.site.count > 0 || statistic.app.count ? (
          <SwiperSlide>
            <Card className="h-100 shadow-none box-bg-orange" body>
              <div className="mask"></div>
              <h5 className="mb-2 fw-6 h6">{t("Источник")}</h5>
              <Row className="gx-1">
                {(statistic.site.total > 0 || statistic.site.count > 0) && (
                  <Col xl={6} xxl={4}>
                    <p className="fs-08">{t("Сайт")}</p>
                    <p className="fw-7 fs-09 mb-1">
                      {customPrice(statistic.site.total)} (
                      {statistic.site.count})
                    </p>
                  </Col>
                )}
                {(statistic.app.total > 0 || statistic.app.count > 0) && (
                  <Col xl={6} xxl={4}>
                    <p className="fs-08">{t("Приложение")}</p>
                    <p className="fw-7 fs-09 mb-1">
                      {customPrice(statistic.app.total)} ({statistic.app.count})
                    </p>
                  </Col>
                )}
              </Row>
            </Card>
          </SwiperSlide>
        ) : (
          ""
        )}
      </Swiper>

      <DataTable
        columns={orderColumns}
        onChange={(items) => setSelected(items)}
        data={orders.items}
        updateData={user}
        header={header}
        selectable
        pagination={orders.pagination}
        backgroundColor={(row) =>
          row?.statuses?.length > 0 &&
          convertColor(isStatus(row.statuses)?.active?.color, 0.07)
        }
        desc={(row) => (
          <div className="d-flex fs-09 p-2 desc align-items-start">
            <Col md={8} className="py-0 pe-2">
              <Row>
                {row?.products?.length > 0 &&
                  row?.products[0] &&
                  row.products.map((e) => (
                    <Col md={4} className="order-product pb-3">
                      <div className="d-flex align-items-center">
                        {/* <div className="pe-2">
                          <img
                            src={getImageURL({ path: e.medias })}
                            width={35}
                            height={35}
                          />
                        </div> */}
                        <div>
                          <p className="fs-09">
                            {e.title}
                            <span className="text-success p-0 me-1 fw-7">
                              <span className="fw-4 text-muted ms-2 me-2">
                                x
                              </span>
                              {e.count}
                            </span>
                          </p>
                          <p className="fw-7 fs-09">
                            {e.discount > 0 &&
                              customPrice(
                                e?.modifiers?.length > 0
                                  ? e.options.modifierPriceSum
                                    ? e.modifiers.reduce(
                                        (sum, item) => sum + item.price,
                                        0
                                      ) *
                                        e.count +
                                      e.price * e.count -
                                      e.discount
                                    : e.modifiers.reduce(
                                        (sum, item) => sum + item.price,
                                        0
                                      ) *
                                        e.count -
                                      e.discount
                                  : e.price * e.count - e.discount,
                                false,
                                user?.lang
                              )}
                            <span
                              className={e.discount > 0 ? "discount ms-1" : ""}
                            >
                              {customPrice(
                                e?.modifiers?.length > 0
                                  ? e.options.modifierPriceSum
                                    ? e.modifiers.reduce(
                                        (sum, item) => sum + item.price,
                                        0
                                      ) +
                                      e.price * e.count
                                    : e.modifiers.reduce(
                                        (sum, item) => sum + item.price,
                                        0
                                      ) * e.count
                                  : e.price * e.count,
                                false,
                                user?.lang
                              )}
                            </span>
                          </p>
                        </div>
                      </div>
                    </Col>
                  ))}
              </Row>
              {row.comment && (
                <div className="comment">
                  <p className="text-muted fs-08">{t("Комментарий")}</p>
                  {row.comment}
                </div>
              )}
            </Col>
            <Col md={4} className="flex-column">
              {row?.uid && (
                <p className="mb-2">
                  <span className="text-muted me-2">{t("ID")}:</span>
                  <b>{row.uid.toUpperCase()}</b>
                </p>
              )}
              {row.name && (
                <p className="mb-2">
                  <span className="text-muted me-2">{t("Заказчик")}:</span>
                  {row.name}
                </p>
              )}

              {row.person > 0 && (
                <p className="mb-2">
                  <span className="text-muted me-2">{t("Приборов")}:</span>
                  {row.person}
                </p>
              )}
              {row.serving && (
                <p className="mb-2">
                  <span className="text-muted me-2">{t("Ко времени")}:</span>
                  {row.serving ? (
                    moment(row.serving).format("DD.MM.YYYY kk:mm")
                  ) : (
                    <span className="text-success">{t("Сейчас")}</span>
                  )}
                </p>
              )}

              {row.payment && (
                <p className="mb-2">
                  <span className="text-muted me-2">{t("Оплата")}:</span>
                  {t(
                    row.payment == "online"
                      ? "Онлайн"
                      : row.payment == "card"
                      ? "Банковская карта"
                      : row.payment == "cash"
                      ? "Наличка"
                      : "Неизвестно"
                  )}
                </p>
              )}

              {row.delivery === "delivery" ? (
                <p className="mb-2">
                  <span className="text-muted me-2">{t("Доставка")}:</span>
                  {row.city} {row.street} {row.home}
                  {row.block ? " корпус " + row.block : ""}
                  {row.entrance ? " подъезд " + row.entrance : ""}
                  {row.floor ? " этаж " + row.floor : ""}
                  {row.apartment ? " кв " + row.apartment : ""}
                  {row.code ? " код " + row.code : ""}
                </p>
              ) : (
                <p className="mb-2">
                  <span className="text-muted me-2">{t("Самовывоз")}</span>
                </p>
              )}
              {row?.noteCount && (
                <p className="mb-2">
                  <span className="text-muted me-2">{t("Примечание")}:</span>
                  <span class="ms-1 badge bg-danger">{row.noteCount}</span>
                </p>
              )}
              {row.deliveryPrice > 0 && (
                <p className="mb-2">
                  <span className="text-muted me-2">
                    {t("Стоимость доставки")}:
                  </span>
                  {customPrice(row.deliveryPrice, false, user?.lang)}
                </p>
              )}

              {row.pointAccrual > 0 && (
                <p className="mb-2">
                  <span className="text-muted me-2">
                    {t("Начисленно баллов")}:
                  </span>
                  +{customPrice(row.pointAccrual, "Б")}
                </p>
              )}
              {row.pointWriting > 0 && (
                <p className="mb-2">
                  <span className="text-muted me-2">
                    {t("Списано баллов")}:
                  </span>
                  -{customPrice(row.pointWriting, "Б")}
                </p>
              )}
              {row.pickupDescount > 0 && (
                <p className="mb-2">
                  <span className="text-muted me-2">
                    {t("Скидка за самовывоз")}:
                  </span>
                  -{customPrice(row.pickupDescount)}
                </p>
              )}
              {row.discount > 0 && (
                <p className="mb-2">
                  <span className="text-muted me-2">{t("Скидка")}:</span>-
                  {customPrice(row.discount)}
                </p>
              )}
            </Col>
          </div>
        )}
      />
      <CustomModal
        title={`Удаление ${modalDelete.id ? "#" + modalDelete.id : ""}`}
        isShow={modalDelete.isShow}
        setShow={(e) => setModalDelete({ isShow: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={(e) =>
                setModalDelete({ isShow: !modalDelete.isShow, id: false })
              }
            >
              {t("Отмена")}
            </Button>
            <Button
              className="btn-danger"
              onClick={() => modalDelete.id && clickDelete(modalDelete.id)}
            >
              {t("Удалить")}
            </Button>
          </>
        }
      >
        {t("Вы точно хотите удалить заказ?")}
      </CustomModal>
    </>
  );
};

export default OrderComponent;
