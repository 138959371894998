import React from "react";
import { NavLink } from "react-router-dom";
import { MdHomeFilled, MdMenuBook } from "react-icons/md";
import { HiShoppingCart, HiUserCircle } from "react-icons/hi";
import Container from "react-bootstrap/Container";
import { useSelector } from "react-redux";
import {
  IoHomeOutline,
  IoListOutline,
  IoPeopleOutline,
  IoSettingsOutline,
} from "react-icons/io5";

const MobileNav = ({ onClickAccount }) => {
  const cart = useSelector((state) => state?.cart);

  return (
    <nav className="mobile-nav">
      <Container className="h-100 d-lg-flex align-items-center">
        <ul className="list-unstyled">
          <li>
            <NavLink to="/" end>
              <svg
                className="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.32"
                  d="M4.32992 6C3.84871 6 3.43524 6.34385 3.34854 6.8172C1.98294 14.2724 1.75 18 1.75 18H22.25C22.25 18 22.017 14.2724 20.6514 6.8172C20.5647 6.34385 20.1513 6 19.67 6H4.32992Z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M22.25 18H1.75C1.75 18 1.75 18.695 1.80122 19.6754C1.87282 21.0459 2.95622 22.0861 4.32766 22.138C5.855 22.1959 8.2782 22.25 12 22.25C15.7218 22.25 18.145 22.1959 19.6723 22.138C21.0438 22.0861 22.1272 21.0459 22.1988 19.6754C22.25 18.695 22.25 18 22.25 18Z"
                />
                <path d="M12 1C9.2386 1 7 3.23857 7 6H9C9 4.34314 10.3432 3 12 3C13.6568 3 15 4.34314 15 6H17C17 3.23857 14.7614 1 12 1Z" />
              </svg>
            </NavLink>
          </li>
          <li>
            <NavLink to="/users">
              <svg
                className="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.32"
                  d="M2.28099 19.6575C2.36966 20.5161 2.93261 21.1957 3.77688 21.3755C5.1095 21.6592 7.6216 22 12 22C16.3784 22 18.8905 21.6592 20.2232 21.3755C21.0674 21.1957 21.6304 20.5161 21.719 19.6575C21.8505 18.3844 22 16.0469 22 12C22 7.95305 21.8505 5.6156 21.719 4.34251C21.6304 3.48389 21.0674 2.80424 20.2231 2.62451C18.8905 2.34081 16.3784 2 12 2C7.6216 2 5.1095 2.34081 3.77688 2.62451C2.93261 2.80424 2.36966 3.48389 2.28099 4.34251C2.14952 5.6156 2 7.95305 2 12C2 16.0469 2.14952 18.3844 2.28099 19.6575Z"
                />
                <path d="M13.9382 13.8559C15.263 13.1583 16.1663 11.768 16.1663 10.1666C16.1663 7.8655 14.3008 6 11.9996 6C9.69841 6 7.83291 7.8655 7.83291 10.1666C7.83291 11.768 8.73626 13.1584 10.0612 13.856C8.28691 14.5319 6.93216 16.1092 6.51251 18.0529C6.45446 18.3219 6.60246 18.5981 6.87341 18.6471C7.84581 18.8231 9.45616 19 12.0006 19C14.545 19 16.1554 18.8231 17.1278 18.6471C17.3977 18.5983 17.5454 18.3231 17.4876 18.0551C17.0685 16.1103 15.7133 14.5321 13.9382 13.8559Z" />
              </svg>
            </NavLink>
          </li>
          <li>
            <NavLink to="/catalog/categories">
              <svg
                className="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.32"
                  d="M15.2798 4.5H4.7202C3.77169 4.5 3 5.06057 3 5.75042C3 6.43943 3.77169 7 4.7202 7H15.2798C16.2283 7 17 6.43943 17 5.75042C17 5.06054 16.2283 4.5 15.2798 4.5Z"
                />
                <path d="M19.2798 10.75H8.7202C7.77169 10.75 7 11.3106 7 12.0004C7 12.6894 7.77169 13.25 8.7202 13.25H19.2798C20.2283 13.25 21 12.6894 21 12.0004C21 11.3105 20.2283 10.75 19.2798 10.75Z" />
                <path d="M15.2798 17H4.7202C3.77169 17 3 17.5606 3 18.2504C3 18.9394 3.77169 19.5 4.7202 19.5H15.2798C16.2283 19.5 17 18.9394 17 18.2504C17 17.5606 16.2283 17 15.2798 17Z" />
              </svg>
            </NavLink>
          </li>
          <li>
            <NavLink to="/dialogs">
              <svg
                className="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.32"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.12319 2.24369C5.3177 2.12083 7.30475 2 10.5 2C13.6952 2 15.6823 2.12083 16.8768 2.24369C17.8972 2.34863 18.6398 3.10549 18.7572 4.12444C18.8797 5.18775 19 6.7933 19 9C19 11.2067 18.8797 12.8123 18.7572 13.8756C18.6398 14.8945 17.8973 15.6514 16.877 15.7563C15.822 15.8648 14.1489 15.9717 11.571 15.9952C11.1714 15.9989 10.7875 16.1592 10.507 16.4437L8.816 18.1584C8.08185 18.9029 6.8125 18.4707 6.6853 17.4328L6.55145 16.3414C6.52175 16.099 6.3197 15.9137 6.0759 15.9011C5.26545 15.859 4.62502 15.8079 4.12397 15.7564C3.10365 15.6515 2.36022 14.8945 2.24278 13.8756C2.12023 12.8123 2 11.2067 2 9C2 6.7933 2.12023 5.18775 2.24278 4.12444C2.36022 3.10549 3.10287 2.34863 4.12319 2.24369ZM7 6C6.4477 6 6 6.4477 6 7C6 7.5523 6.4477 8 7 8H14C14.5523 8 15 7.5523 15 7C15 6.4477 14.5523 6 14 6H7ZM7 10.5C6.4477 10.5 6 10.9477 6 11.5C6 12.0523 6.4477 12.5 7 12.5H11C11.5523 12.5 12 12.0523 12 11.5C12 10.9477 11.5523 10.5 11 10.5H7Z"
                />
                <path d="M5.99902 7C5.99902 6.4477 6.44672 6 6.99902 6H13.999C14.5513 6 14.999 6.4477 14.999 7C14.999 7.5523 14.5513 8 13.999 8H6.99902C6.44672 8 5.99902 7.5523 5.99902 7Z" />
                <path d="M5.99902 11.5C5.99902 10.9477 6.44672 10.5 6.99902 10.5H10.999C11.5513 10.5 11.999 10.9477 11.999 11.5C11.999 12.0523 11.5513 12.5 10.999 12.5H6.99902C6.44672 12.5 5.99902 12.0523 5.99902 11.5Z" />
                <path d="M10.0257 16.9308L10.506 16.4437C10.7866 16.1592 11.1705 15.9989 11.57 15.9952C14.1479 15.9717 15.821 15.8648 16.876 15.7563C17.8963 15.6514 18.6388 14.8945 18.7562 13.8755C18.8363 13.1809 18.9154 12.2547 18.9606 11.0808C19.4674 11.1109 19.8664 11.1462 20.1773 11.1811C20.8028 11.2515 21.2614 11.7033 21.3392 12.3279C21.4206 12.9808 21.499 13.9995 21.499 15.5C21.499 17.0005 21.4206 18.0192 21.3392 18.6721C21.2614 19.2967 20.8021 19.7486 20.1766 19.8189C19.7585 19.8659 19.1808 19.9136 18.3962 19.9483C18.1641 19.9585 17.9673 20.1252 17.9223 20.3531L17.7516 21.219C17.6396 21.7868 16.9538 22.0192 16.5197 21.6364L15.0712 20.3589C14.8029 20.1223 14.4575 19.9901 14.0999 19.9816C12.7849 19.9504 11.9009 19.884 11.3222 19.819C10.6967 19.7488 10.2366 19.2967 10.1588 18.6721C10.1066 18.2528 10.0555 17.6826 10.0257 16.9308Z" />
              </svg>
            </NavLink>
          </li>
          <li>
            <NavLink to="/documents" onClick={onClickAccount}>
              <svg
                className="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.5565 2.10053C3.94224 2.2122 2 3.88336 2 6.5C2 9.11665 3.94224 10.7878 6.5565 10.8995C7.9246 10.9579 9.7062 11 12 11C14.2938 11 16.0754 10.9579 17.4435 10.8995C20.0577 10.7878 22 9.11665 22 6.5C22 3.88336 20.0577 2.2122 17.4435 2.10053C16.0754 2.04209 14.2938 2 12 2C9.7062 2 7.9246 2.04209 6.5565 2.10053ZM20 6.5C20 7.88071 18.8807 9 17.5 9C16.1193 9 15 7.88071 15 6.5C15 5.11929 16.1193 4 17.5 4C18.8807 4 20 5.11929 20 6.5Z"
                />
                <path
                  opacity="0.32"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.4435 13.1005C20.0577 13.2122 22 14.8833 22 17.5C22 20.1167 20.0577 21.7878 17.4435 21.8995C16.0754 21.9579 14.2938 22 12 22C9.7062 22 7.9246 21.9579 6.5565 21.8995C3.94224 21.7878 2 20.1167 2 17.5C2 14.8833 3.94224 13.2122 6.5565 13.1005C7.9246 13.0421 9.7062 13 12 13C14.2938 13 16.0754 13.0421 17.4435 13.1005ZM4 17.5C4 18.8807 5.11929 20 6.5 20C7.88071 20 9 18.8807 9 17.5C9 16.1193 7.88071 15 6.5 15C5.11929 15 4 16.1193 4 17.5Z"
                />
                <path
                  opacity="0.32"
                  d="M17.5 9C18.8807 9 20 7.88071 20 6.5C20 5.11929 18.8807 4 17.5 4C16.1193 4 15 5.11929 15 6.5C15 7.88071 16.1193 9 17.5 9Z"
                />
                <path d="M6.5 20C5.11929 20 4 18.8807 4 17.5C4 16.1193 5.11929 15 6.5 15C7.88071 15 9 16.1193 9 17.5C9 18.8807 7.88071 20 6.5 20Z" />
              </svg>
            </NavLink>
          </li>
        </ul>
      </Container>
    </nav>
  );
};

export default MobileNav;
