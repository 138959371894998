import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import socket from "../../config/socket";
import { login } from "../../services/auth";
import { setAffiliates } from "../../store/reducers/affiliateSlice";
import { setAuth, setUser } from "../../store/reducers/authSlice";
import { setBrands } from "../../store/reducers/brandSlice";
import { setStatuses } from "../../store/reducers/statusSlice";
import { getImageURL } from "../../helpers/image";
import Select from "../../components/UI/Select";
import { useTranslation } from "react-i18next";
import { languageCode } from "../../helpers/all";
import moment from "moment";

const Login = () => {
  const { t, i18n } = useTranslation();
  const affiliateData = useSelector((state) => state.affiliate);
  const [loading, setLoading] = useState(false);
  const optionsBrand = useSelector((state) => state.settings.brand);
  var cache = JSON.parse(localStorage.getItem("cache")) ?? [];
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({ mode: "all", reValidateMode: "onSubmit" });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (data) => {
      setLoading(true);
      await login(data)
        .then((res) => {
          localStorage.setItem("token", res.token);

          if (res?.member?.lang) {
            i18n.changeLanguage(languageCode(res.member.lang));
            moment.locale(languageCode(res.member.lang));
          }

          dispatch(setUser(res.member));
          dispatch(setAuth(true));

          socket.io.opts.query = {
            partnerId: res?.member?.id,
            memberId: res.member.id,
          };
          socket.connect();

          NotificationManager.success("Вы вошли в аккаунт");

          navigate("/");
        })
        .catch((error) => {
          NotificationManager.error(
            typeof error?.response?.data?.error == "string"
              ? error.response.data.error
              : "Неизвестная ошибка"
          );
        })
        .finally(() => setLoading(false));
    },
    [cache, affiliateData]
  );

  return (
    <>
      <Meta title="Войти" desc="Войти в профиль" />
      <Row className="gx-0 hv-100-important">
        <Col lg={8} md={7} className="login-info d-none d-md-flex">
          <div className="flex-column d-flex align-self-center justify-content-center align-items-center">
            <img
              src={
                optionsBrand?.logo
                  ? getImageURL({
                      path: optionsBrand.logo,
                      type: "all/brand/logo",
                      size: "full",
                    })
                  : "/logo-gray.png"
              }
              height={80}
            />
            <h2 className="my-4 text-center">Партнерский аккаунт</h2>
            <img src="./images/auth/login.svg" width="80%" />
          </div>
        </Col>
        <Col lg={4} md={5}>
          <div className="login">
            <div className="login-form">
              <h3 className="mb-3 h5 fw-7 w-100">Войдите в свой профиль</h3>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Input
                  autoFocus={true}
                  label="Логин"
                  name="login"
                  errors={errors}
                  register={register}
                  validation={{
                    required: "Введите логин",
                    maxLength: {
                      value: 250,
                      message: "Максимально 250 символов",
                    },
                  }}
                />
                <div className="mt-4">
                  <Input
                    label="Пароль"
                    type="password"
                    name="password"
                    errors={errors}
                    register={register}
                    validation={{
                      required: "Введите пароль",
                      minLength: {
                        value: 4,
                        message:
                          "Минимальный пароль должен состоять из 4-ех символов",
                      },
                    }}
                  />
                </div>
                <Button
                  type="submit"
                  className="btn-primary mt-3 w-100"
                  disabled={!isValid}
                  isLoading={loading}
                >
                  Войти
                </Button>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Login;
