import React, { useCallback, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Select from "../../components/UI/Select";
import { generateKey } from "../../helpers/all";
import { getRoleData, roles } from "../../helpers/member";
import { createMember } from "../../services/member";

const MemberCreate = () => {
  const affiliate = useSelector((state) => state.affiliate);
  const [btnLoading, setBtnLoading] = useState(false);
  const affiliatesData =
    affiliate?.data?.length > 0
      ? affiliate.data.filter((e, index) => index !== 0)
      : [];

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "all",
    reValidateMode: "onSubmit",
    defaultValues: {
      brandToken: generateKey(37),
      rate: "rent",
    },
  });
  const form = useWatch({ control });

  const onSubmit = useCallback((data) => {
    setBtnLoading(true);
    createMember(data)
      .then(() => NotificationManager.success("Данные успешно обновлены"))
      .catch((error) =>
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        )
      )
      .finally(() => setBtnLoading(false));
  }, []);

  return (
    <>
      <Meta title="Добавление клиента" />
      <div className="d-flex justify-content-between align-items-center">
        <Link
          to="/brands"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
      </div>
      <h3 className="mb-4">Добавление клиента</h3>
      <Card>
        <Card.Body>
          <Row>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  autocomplete="off"
                  label="Номер телефона"
                  name="phone"
                  placeholder="+7(000)000-00-00"
                  mask="+7(999)999-99-99"
                  errors={errors}
                  register={register}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  autocomplete="off"
                  type="date"
                  label="День рождения"
                  placeholder="Введите день рождения (Необязательно)"
                  name="brithday"
                  errors={errors}
                  register={register}
                />
              </div>
            </Col>
            <Col md={4}>
              <Select
                className="mb-4"
                label="Пол"
                onClick={(e) => setValue("sex", e.value)}
                value={form.sex ?? ""}
                data={[
                  { title: "Не указано", value: "" },
                  { title: "Мужской", value: "man" },
                  { title: "Женский", value: "woman" },
                ]}
              />
            </Col>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  autocomplete="off"
                  label="Фамилия"
                  name="lastName"
                  placeholder="Введите фамилию (Необязательно)"
                  errors={errors}
                  register={register}
                  validation={{
                    maxLength: {
                      value: 50,
                      message: "Максимально 50 символов",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  autocomplete="off"
                  label="Имя"
                  name="firstName"
                  errors={errors}
                  register={register}
                  placeholder="Введите имя"
                  validation={{
                    required: "Введите имя",
                    maxLength: {
                      value: 20,
                      message: "Максимально 20 символов",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  label="Отчество"
                  name="patronymic"
                  autocomplete="off"
                  errors={errors}
                  register={register}
                  placeholder="Введите отчество (Необязательно)"
                  validation={{
                    maxLength: {
                      value: 50,
                      message: "Максимально 50 символов",
                    },
                  }}
                />
              </div>
            </Col>

            <Col md={6}>
              <div className="mb-4">
                <Input
                  autocomplete="off"
                  label="Юр Лицо"
                  name="organization.name"
                  errors={errors}
                  register={register}
                  placeholder="ИП Иванов Иван Иванович"
                  validation={{
                    maxLength: {
                      value: 250,
                      message: "Максимально 250 символов",
                    },
                  }}
                />
              </div>
            </Col>

            <Col md={6}>
              <div className="mb-4">
                <Input
                  autocomplete="off"
                  label="ИНН"
                  name="organization.inn"
                  errors={errors}
                  register={register}
                  placeholder="Введите ИНН"
                  validation={{
                    maxLength: {
                      value: 20,
                      message: "Максимально 20 символов",
                    },
                  }}
                />
              </div>
            </Col>
          </Row>
          <h5 className="mb-4">Данные для входа</h5>
          <Row>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  autocomplete="off"
                  label="Email"
                  name="email"
                  placeholder="Введите email"
                  errors={errors}
                  register={register}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  autocomplete="off"
                  label="Пароль"
                  name="password"
                  type="password"
                  errors={errors}
                  placeholder="Введите пароль"
                  register={register}
                  validation={{
                    required: "Введите пароль",
                    maxLength: {
                      value: 200,
                      message: "Максимально 200 символов",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  autocomplete="off"
                  label="Повторный пароль"
                  name="passwordConfirm"
                  type="password"
                  errors={errors}
                  placeholder="Введите повторно пароль"
                  register={register}
                  validation={{
                    required: "Введите повторно пароль",
                    maxLength: {
                      value: 200,
                      message: "Максимально 200 символов",
                    },
                  }}
                />
              </div>
            </Col>
          </Row>
          <h5 className="mb-4">Бренд и лицензия</h5>
          <Row>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  label="Название"
                  name="brandName"
                  errors={errors}
                  register={register}
                  placeholder="Введите название бренда"
                  validation={{
                    required: "Обязательное поле",
                    maxLength: {
                      value: 50,
                      message: "Максимально 50 символов",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  label="Описание"
                  name="brandDesc"
                  errors={errors}
                  register={register}
                  placeholder="Введите описание бренда"
                  validation={{
                    maxLength: {
                      value: 500,
                      message: "Максимально 500 символов",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  readOnly={false}
                  label="Токен"
                  name="brandToken"
                  errors={errors}
                  register={register}
                  validation={{
                    required: "Обязательное поле",
                  }}
                />
              </div>
            </Col>
          </Row>
          <div className="d-flex justify-content-end">
            <Button
              disabled={!isValid}
              isLoading={btnLoading}
              onClick={handleSubmit(onSubmit)}
            >
              Сохранить изменения
            </Button>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default MemberCreate;
