import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/free-mode";
import "swiper/css/mousewheel";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./assets/styles/style.min.css";
import Loader from "./components/UI/Loader";
import socket from "./config/socket";
import { languageCode } from "./helpers/all";
import AppRouter from "./routes/AppRouter";
import { checkAuth, logout } from "./services/auth";
import { setAuth, setUser } from "./store/reducers/authSlice";
import { updateIp } from "./store/reducers/settingsSlice";

const App = () => {
  const { i18n } = useTranslation();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (localStorage.getItem("token")) {
        await axios
          .get("https://ip.yooapp.ru")
          .then(({ data }) => data?.ip && dispatch(updateIp(data.ip)));

        await checkAuth()
          .then((data) => {
            data.member && dispatch(setUser(data.member));
            data.member && dispatch(setAuth(true));
            if (data?.member?.lang) {
              i18n.changeLanguage(languageCode(data.member.lang));
              moment.locale(languageCode(data.member.lang));
            }

            socket.io.opts.query = {
              partnerId: data?.member?.id,
              memberId: data.member.id,
            };
            socket.connect();
            socket.emit("member/add", {
              memberId: data.member.id,
            });
          })
          .catch((err) => err?.response?.status === 404 && dispatch(logout()))
          .finally(() => setLoading(false));
      } else {
        setLoading(false);
      }
      return () => socket.off("connect");
    })();
  }, []);

  if (loading) {
    return <Loader full />;
  }

  return <AppRouter />;
};
export default App;
