import React, { useState } from "react";
import { Badge, Card, Col, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoReloadOutline } from "react-icons/io5";
import Button from "../../../components/UI/Button";
import Input from "../../../components/UI/Input";
import CustomModal from "../../../components/utils/CustomModal";
import { generateKey } from "../../../helpers/all";

const TelegramForm = ({ data, onSubmit }) => {
  const [modalUpdate, setModalUpdate] = useState({
    show: false,
    data: false,
  });

  const {
    control,
    setValue,
    formState: { isValid },
    handleSubmit,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: data,
  });

  const form = useWatch({ control });

  return (
    <Card body className="mb-4">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <span>
          <h5 className="mb-4">Настройки</h5>
        </span>
        <span>
          {data?.relationModule?.options[0]?.groupId ? (
            <Badge bg="success">Активен</Badge>
          ) : (
            <Badge bg="secondary">Отключен</Badge>
          )}
        </span>
      </div>
      <div className="mb-4">
        Для подключения, нужно: <br />
        1. Добавить{" "}
        <a className="fw-6" href="https://t.me/iyooapp_bot" target="_blank">
          @iyooapp_bot
        </a>{" "}
        бот в нужную группу с правами администратора. Можно так же подключить в
        личных сообщениях с ботом.
        <br />
        2. После добавления, запустите бот командой <b>/start</b>
        <br />
        3. Отправьте следующим сообщением ключ подтверждения, сгенерированный
        ниже.
        <br />
        <br />
        Если вы захотите поменять группу, то обновите ключ подтверждения нажав
        на иконку обновления. Проделайте вшеупомняутые шаги.
        <br />
        Может быть добавлена только одна группа или чат.
      </div>
      <Row>
        <Col lg={6}>
          <div className="mb-3">
            <Input
              readOnly={false}
              label="Ключ"
              placeholder="Здесь будет указан одноразовый ключ"
              value={form?.relationModule?.options[0]?.key}
              rightIcon={() => <IoReloadOutline size={22} />}
              rightIconClick={() =>
                setModalUpdate({
                  index: 0,
                  show: true,
                  data: {
                    ...form.relationModule.options[0],
                    key: "4ashw4" + generateKey(30),
                    groupId: "",
                  },
                })
              }
            />
            <small className="text-muted">
              Ключ нужно отправить в сообщении группы или чата
            </small>
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Input
              readOnly={false}
              label="ID группы|чата"
              placeholder="Здесь будет указан ID"
              value={form?.relationModule?.options[0]?.groupId}
            />
          </div>
        </Col>
        {/* <Col lg={3}>
          <div className="mb-3">
            <Select
              label="Тип"
              value={form?.relationModule?.options[0]?.type ?? ""}
              data={[
                { title: "Все", value: "" },
                { title: "Заказы", value: "order" },
                { title: "Системные", value: "system" },
              ]}
              onClick={(e) =>
                setValue("relationModule.options.0.type", e.value)
              }
            />
          </div>
        </Col> */}
      </Row>
      {/* <Row>
        <Col lg={5}>
          <div className="mb-3">
            <Input
              readOnly={false}
              label="Ключ"
              placeholder="Здесь будет указан одноразовый ключ"
              value={form?.relationModule?.options[1]?.key}
              rightIcon={() => <IoReloadOutline size={22} />}
              rightIconClick={() =>
                setModalUpdate({
                  index: 1,
                  show: true,
                  data: {
                    ...form.relationModule.options[1],
                    key: "4ashw4" + generateKey(30),
                    groupId: "",
                  },
                })
              }
            />
            <small className="text-muted">
              Ключ нужно отправить в сообщении группы или чата
            </small>
          </div>
        </Col>
        <Col lg={4}>
          <div className="mb-3">
            <Input
              readOnly={false}
              label="ID группы|чата"
              placeholder="Здесь будет указан ID"
              value={form?.relationModule?.options[1]?.groupId}
            />
          </div>
        </Col>
        <Col lg={3}>
          <div className="mb-3">
            <Select
              label="Тип"
              value={form?.relationModule?.options[1]?.type ?? ""}
              data={[
                { title: "Все", value: "" },
                { title: "Заказы", value: "order" },
                { title: "Системные", value: "system" },
              ]}
              onClick={(e) =>
                setValue("relationModule.options.1.type", e.value)
              }
            />
          </div>
        </Col>
      </Row> */}
      <div className="d-flex justify-content-end">
        <Button isValid={isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить изменения
        </Button>
      </div>

      <CustomModal
        title="Подтвердите действие"
        show={modalUpdate.show}
        setShow={(e) => setModalUpdate({ index: false, show: e, data: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() =>
                setModalUpdate({
                  index: false,
                  show: !modalUpdate.show,
                  data: false,
                })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              onClick={() => {
                setValue(
                  `relationModule.options.${modalUpdate.index}`,
                  modalUpdate.data
                );
                setModalUpdate({
                  index: false,
                  show: !modalUpdate.show,
                  data: false,
                });
              }}
            >
              Обновить
            </Button>
          </>
        }
      >
        Вы точно хотите обновить ключ? Группа или чат будут отключены от
        системы.
      </CustomModal>
    </Card>
  );
};
export default TelegramForm;
